import gql from 'graphql-tag'

export const web3AuthNonce = gql`mutation web3AuthNonce ($address: String!) {
  web3AuthNonce(address: $address) {
    nonce
  }
}`

export const web3AuthVerify = gql`mutation web3AuthVerify ($chainName: ChainName!, $address: String!, $nonce: String!, $signature: String!) {
  web3AuthVerify(chainName: $chainName, address: $address, nonce: $nonce, signature: $signature) {
    token
    user {
      id
      displayName
      avatarImg
    }
  }
}`

export const profile = gql`query profile ($id: uuid!) {
  profile: profiles_by_pk(id: $id) {
    id
    displayName
    avatarImg
    wallets {
      id
      type
      chainName
      address
      verifiedAt
    }
  }
}`

export const ownProfile = gql`query ownProfile ($id: uuid!) {
  profile: profiles_by_pk(id: $id) {
    id
    displayName
    avatarImg
    wallets {
      id
      type
      chainName
      chain {
        name
        humanName
        symbol
      }
      address
      verifiedAt
    }
  }
  
  account: auth_accounts_by_pk(id: $id) {
    id
    email
  }
}`

export const updateProfile = gql`mutation updateProfile ($id: uuid!, $displayName: String, $avatarImg: String, $email: String) {
  account: update_auth_accounts_by_pk(pk_columns: { id: $id }, _set: { email: $email }) {
    id
    email
  }

  profile: update_profiles_by_pk(pk_columns: { id: $id }, _set: { displayName: $displayName, avatarImg: $avatarImg }) {
    id
    displayName
    avatarImg
  }
}`

export const updateName = gql`mutation updateName ($id: uuid!, $displayName: String!) {
  profile: update_profiles_by_pk(pk_columns: { id: $id }, _set: { displayName: $displayName }) {
    id
    displayName
  }
}`

export const updateEmail = gql`mutation updateEmail ($id: uuid!, $email: String!) {
  account: update_auth_accounts_by_pk(pk_columns: { id: $id }, _set: { email: $email }) {
    id
    email
  }
}`
