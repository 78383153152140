<template>
  <button :type="type" :class="[base, style, size]" :disabled="loading">
    <svg v-if="loading" class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    <template v-else>
      <component v-if="iconComponent" :is="iconComponent" :class="[icon, 'h-5 w-5 -ml-1.5 mr-2']" aria-hidden="true" />
      <span><slot></slot></span>
    </template>
  </button>
</template>

<script setup>
import { computed } from 'vue'
import { upperFirst } from 'lodash'
import { DiscordIcon, TwitterIcon, MetamaskIcon, PhantomIcon } from '@ui'
import * as iconsOutline from '@heroicons/vue/outline'
import * as iconsSolid from '@heroicons/vue/solid'

const props = defineProps({
  // Colors
  primary: Boolean,
  secondary: Boolean,
  color: String,

  // Size
  lg: Boolean,
  xl: Boolean,

  // Icons
  icon: String,
  iconsSolid: Boolean,

  // State
  type: String,
  loading: Boolean
})

const base = `inline-flex justify-center font-medium rounded-md shadow-sm border focus:outline-none focus:ring-2 focus:ring-offset-2`

const color = computed(() => props.color || 'pink')
const style = computed(() => {
  if (props.primary) return `border-transparent text-white bg-${color.value}-600 hover:bg-${color.value}-700 focus:ring-${color.value}-500`
  if (props.secondary) return `border-transparent text-${color.value}-700 bg-${color.value}-100 hover:bg-${color.value}-200 focus:ring-${color.value}-500`
  return `border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-gray-500`
})

const size = computed(() => {
  if (props.lg) return 'text-base px-5 py-2'
  return 'text-sm px-4 py-2'
})

// Icon color
const icon = computed(() => {
  if (props.color === 'white') return 'text-gray-400';
  if (props.primary) return 'text-white'
  return `text-${color.value}-600`
});

const iconComponent = computed(() => {
  if (props.icon === 'discord') return DiscordIcon
  if (props.icon === 'twitter') return TwitterIcon
  if (props.icon === 'metamask') return MetamaskIcon
  if (props.icon === 'phantom') return PhantomIcon
  const name = `${upperFirst(props.icon)}Icon`
  return props.iconSolid ? iconsSolid[name] : iconsOutline[name]
})
</script>