import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth.module'
import data from './data.module'
import flappy from './flappy.module'

const store = createStore({
  modules: {
    auth,
    data,
    flappy,
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'data']
    })
  ],
});

export default store;
