<template>
  <Menu as="div" class="px-3 relative inline-block text-left">
    <div class="pt-1">
      <MenuButton class="group w-full bg-white rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-pink-500 shadow">
        <span class="flex w-full justify-between items-center">
          <span v-if="profile" class="flex min-w-0 items-center justify-between space-x-3">
            <div class="inline-block flex-shrink-0 relative">
              <div class="w-10 h-10 bg-gray-300 rounded-full overflow-hidden">
                <img v-if="profile.avatarImg" class="object-cover" src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" alt="" />
                <svg v-else class="object-cover text-gray-400" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </div>
              <span class="absolute -bottom-1 -right-1 flex items-center justify-center h-5 w-5 rounded-full bg-white">
                <EthIcon v-if="activeWallet.chainName === 'ETHEREUM'" class="inline h-3" aria-hidden="true" />
                <SolanaIcon v-if="activeWallet.chainName === 'SOLANA'" class="inline h-2.5" aria-hidden="true" />
              </span>
            </div>
            <span class="flex-1 flex flex-col min-w-0">
              <span class="text-gray-900 text-sm font-medium truncate">{{ profile.displayName || 'Unnamed' }}</span>
              <span class="text-gray-500 text-sm truncate flex items-center">
                <!-- <EthIcon class="inline h-4 mr-1" /> -->
                {{ $filters.addressFmt(activeWallet.address) }}
              </span>
            </span>
          </span>
          <span v-else class="flex min-w-0 items-center justify-between space-x-3">
            <div class="w-10 h-10 bg-pink-600 rounded-full flex-shrink-0 flex items-center justify-center">
              <LinkIcon class="w-6 h-6 text-white" />
            </div>
            <span class="flex-1 flex flex-col min-w-0">
              <span class="text-gray-900 text-sm font-medium truncate">Login or sign up</span>
              <span class="text-gray-500 text-sm truncate flex items-center">
                Connect wallet
              </span>
            </span>
          </span>
          <SelectorIcon class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        </span>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
        <template v-if="profile">
          <div class="py-1">
            <MenuItem v-for="wallet in profile.wallets" :key="wallet.id" v-slot="{ active }">
              <button @click="setActive(wallet)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">
                <EthIcon v-if="wallet.chainName === 'ETHEREUM'" class="inline mr-3 h-4 w-4" aria-hidden="true" />
                <SolanaIcon v-if="wallet.chainName === 'SOLANA'" class="inline mr-3 h-3 w-4" aria-hidden="true" />
                {{ $filters.addressFmt(wallet.address) }}
              </button>
            </MenuItem>
          </div>
          <div class="py-1" v-if="!hasSolana || !hasEthereum">
            <MenuItem v-if="!hasSolana" v-slot="{ active }">
              <button @click="connect('SOLANA')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">
                <PhantomIcon class="inline mr-3 h-4 w-4" aria-hidden="true" />
                Connect Phantom
              </button>
            </MenuItem>

            <MenuItem v-if="!hasEthereum" v-slot="{ active }">
              <button @click="connect('SOLANA')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">
                <MetamaskIcon class="inline mr-3 h-4 w-4" aria-hidden="true" />
                Connect MetaMask
              </button>
            </MenuItem>
          </div>
          <div class="py-1">
            <MenuItem v-slot="{ active }">
              <AppLink :to="{ name: 'Account' }" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">
                Edit profile
              </AppLink>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button @click="logout" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">
                Logout
              </button>
            </MenuItem>
          </div>
        </template>
        <template v-else>
          <div class="py-1">
            <MenuItem v-slot="{ active }">
              <button @click="connect('SOLANA')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">
                <PhantomIcon class="inline mr-3 h-4 w-4" aria-hidden="true" />
                Login with Phantom
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button @click="connect('ETHEREUM')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">
                <MetamaskIcon class="inline mr-3 h-4 w-4" aria-hidden="true" />
                Login with MetaMask
              </button>
            </MenuItem>
          </div>
        </template>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useQuery, useResult } from '@vue/apollo-composable'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { SelectorIcon, LinkIcon } from '@heroicons/vue/outline'
import { ownProfile } from '@/gql/auth'
import { EthIcon, SolanaIcon, PhantomIcon, MetamaskIcon } from '@ui'
import AppLink from '@/components/roof/AppLink.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const id = computed(() => store.getters['auth/id'])
const activeWallet = computed(() => store.getters['auth/activeWallet'])

const { result, loading, refetch, onResult } = useQuery(ownProfile, { id: id.value })
const profile = useResult(result, null, ({ profile, account }) => ({
  id: account.id,
  email: account.email,
  displayName: profile.displayName,
  avatarImg: profile.avatarImg,
  wallets: profile.wallets,
}))

const hasSolana = computed(() => profile.value && profile.value.wallets.find(({ chainName }) => chainName === 'SOLANA'))
const hasEthereum = computed(() => profile.value && profile.value.wallets.find(({ chainName }) => chainName === 'ETHEREUM'))

// Set active wallet when profile loaded
onResult(() => {
  if (!profile.value) return
  if (!activeWallet.value || !profile.value.wallets.find(({ id }) => id === activeWallet.value.id)) {
    setActive(profile.value.wallets[0])
  }
})

const connect = async (chainName) => {
  loading.value = true
  await store.dispatch('auth/loginWithWeb3', chainName)
  await refetch({ id: id.value })
}

const setActive = (wallet) => {
  store.commit('auth/SET_ACTIVE_WALLET', wallet)
}

const logout = () => {
  store.commit('auth/LOGOUT')
  loading.value = false
  router.push({ name: 'Roof' })
}
</script>